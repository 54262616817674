import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "schedule-a-content-item"
    }}>{`Schedule a Content Item`}</h1>
    <hr></hr>
    <p>{`To schedule a content item from the Content Mangement System, follow these step-by-step instructions.`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Content section from the left side menu options. In the Content Manager, all created Content you have access to will be displayed here in alpabetical order. `}</li>
      <li parentName="ol">{`Select the Content Item you wish to schedule from the list. `}</li>
      <li parentName="ol">{`Select 'Add scheduling on channels'. This action will load the Edit Schedule page. `}</li>
      <li parentName="ol">{`Provide a date range in the From and Until calendars. This details the start date to display the content item and when the display should end. `}</li>
      <li parentName="ol">{`Detail when to display the item by selecting an option under Show:`}
        <ul parentName="li">
          <li parentName="ul">{`Always will constantly display the item during the set time frame in the From and Until fields.`}</li>
          <li parentName="ul">{`Every Day will display the item during certain times. Selecting this and specifying a start and end time will clause the item to be shown during those times e.g. to show an item only between 9 AM and noon.`}</li>
          <li parentName="ul">{`By Day will let you pick which days during the set frame in the From and Until fields to display the item. For example, if set. to Monday, Wednesday and Friday, the item will only display in your app on those specific days. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Leave the dropdown menu set to 'Regular Item'.`}</li>
      <li parentName="ol">{`Select the Channels you want this content item related to.  `}</li>
      <li parentName="ol">{`Select 'Schedule'. `}</li>
      <li parentName="ol">{`When directed back to the Edit Content your configured schedules will display at the bottom of the page. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page. `}</li>
    </ol>
    <p><img alt="CMS Schedule Content Item" src={require("./images/cms_schedule_content_item.gif")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      